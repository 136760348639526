section {
  .arrow-header {
    background-image: url("../../../../assets/img/arrows.png");
    background-size: cover;
    margin: 50px 0px;
    width: 1300px;
    height: 210px;
    margin: 0 auto;
    background-position: top;
    position: relative;
    margin-top: -70px;
    display: flex;
    gap: 100px;
    align-items: flex-end;
    justify-content: center;
    padding: 0px 40px 25px 0px;
  }
  .process-title {
    padding-left: 20px;
    color: #fff;
    width: 300px;
    height: 100px;
    h3 {
      text-align: center;
      max-width: 250px;
      padding-top: 15px;
      font-size: 1.75rem;
    }
  }
  .process-paragraph {
    padding: 50px 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
  }
}

@import "../../common/SCSS/variables.scss";
.white-section {
  padding: 80px 0px;

  .white-section-row {
    align-items: center;
  }
  .text-column {
    h2 {
      color: #36094b;
      text-transform: uppercase;
      font-size: 2.5rem;
      margin-bottom: 60px;
      font-weight: bolder;
    }

    a {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;
      padding: 10px 25px;
      font-weight: bold;
      background-color: #7f2aa7;
      color: #fff;
      transition: 0.3s ease-in;
      width: 200px;
      text-align: center;
      margin-top: 50px;
      &:hover {
        background-color: #36094b;
      }
    }
  }
  img {
    max-width: 450px;
    position: absolute;
    object-fit: cover;
    left: 48%;
    top: -16%;
    transform: translateX(-50%) rotate(-45deg);
    z-index: 70;
    border-image-repeat: none;
  }

  .white-section-img-wrapper {
    width: 380px;
    height: 380px;
    border: 1px solid $primary-purple;
    margin-left: 100px;
    position: relative;
    border-radius: 2%;
    transform: rotate(45deg);
    box-shadow: 0px 0px 30px $primary-light-purple;

    .animation-wrapper {
      transform: rotate(-45deg);
      width: 380px;
      height: 380px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 1210px) {
  .white-section {
    img {
      max-width: 300px;
    }

    .white-section-img-wrapper {
      width: 300px;
      height: 300px;
      margin-left: 45px;

      .animation-wrapper {
        width: 300px;
        height: 300px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .white-section {
    .text-column {
      margin-top: 70px;
      h2 {
        font-size: 1.8rem;
      }
    }
    .white-section-img-wrapper {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .white-section {
    img {
      max-width: 160px;
      top: -10%;
    }

    .white-section-img-wrapper {
      width: 160px;
      height: 160px;

      .animation-wrapper {
        width: 170px;
        height: 170px;
      }
    }
  }
}

@import "../SCSS/variables.scss";
.firstHeader {
  margin-top: 35px;
}
.secondHeader {
  margin-top: -10px;
  margin-bottom: 70px;
}
.hero-component-wrapper {
  height: 100vh;
}

.timer-h {
  color: white;
  font-size: 3.5rem;
}

.timer-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
  }

  .text-zone {
    display: block;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: 1px solid #fff;
    padding: 20px 40px;
    box-sizing: border-box;

    .to-site-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin: 40px 0px 20px;
      .arrow-rotation {
        transform: rotate(180deg);
      }
      .to-site-btn {
        color: #fff;
        font-size: 22px;
        border: 1px solid #fff;
        padding: 10px 30px;
        text-decoration: none;
      }
      .animation-wrapper {
        display: flex;
        span {
          display: block;
          width: 20px;
          height: 20px;
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(135deg);
          animation: animate 1.5s infinite;
          &:nth-child(1) {
            animation-delay: 0.2s;
          }
          &:nth-child(2) {
            animation-delay: -0.2s;
          }
          &:nth-child(3) {
            animation-delay: -0.4s;
          }
        }
      }
    }

    .timer-p {
      margin-top: 10px;
      font-size: 1.5rem;
      font-weight: bold;
      color: antiquewhite;
    }
    .bolder {
      color: #b405fa;
    }
  }

  .timer {
    font-size: 4rem;
    color: #fff;
    display: flex;
    text-align: center;
    position: absolute;
    top: 42%;
    left: 50%;
    margin-left: -400px;
    border: 3px solid #fff;
    padding: 30px;
    gap: 30px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .timer-h {
    color: white;
    font-size: 2rem;
  }
  .timer-container {
    .text-zone {
      width: 90%;
      .timer-p {
        font-size: 1rem;
      }
      .to-site-btn-wrapper {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .timer-container {
    .text-zone {
      width: 100%;
      border: none;
      padding: 0px 20px;
      .timer-p {
        font-size: 1rem;
      }
      .to-site-btn-wrapper {
        width: 100%;
        .to-site-btn {
          font-size: 1rem;
          padding: 0;
          width: 200px;
          padding: 12px 0px;
          border: 2px solid #fff;
        }
      }
    }
  }
}

@import "../common/SCSS/variables.scss";

.counter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  border-right: 5px solid $primary-dark-purple;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 0.5px solid $primary-dark-purple;

  margin: 20px 0px;

  .counter-icon {
    font-size: 3rem;
    color: $primary-dark-purple;
  }
  .counter-value {
    h1 {
      color: $primary-dark-purple;
    }
  }
  h3 {
    color: $primary-dark-purple;
  }
}

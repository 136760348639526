.why-us {
  background-color: #2d1768;

  .row {
    padding-bottom: 60px;
  }
  .why-us-header {
    color: #fff;
    text-align: center;
    margin: 70px 0px 80px;
    font-weight: bolder;
    font-size: 3rem;
    text-transform: uppercase;
  }
  .why-us-h {
    color: #fff;
    margin-bottom: 40px;
  }
  .why-us-p {
    color: #fff;
    text-align: justify;
  }
  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .img-box {
      width: 240px;
      height: 240px;
      background-color: #7500eb;
      border-radius: 10px;
      transform: rotate(45deg);
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px -4px 30px #4f27b1;

      .flat-icons {
        width: 150px;
        height: 150px;
        transform: rotate(315deg);
        filter: grayscale(1) invert(1);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .why-us {
    .img-wrapper {
      margin: 40px 0px;
      .img-box {
        width: 200px;
        height: 200px;
        .flat-icons {
          width: 130px;
          height: 130px;
        }
      }
    }
  }
}

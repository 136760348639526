.tools-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 30px;

  .tool-logo-img {
    width: 100px;
    height: auto;
    filter: grayscale(100%);
    transition: 0.4s ease-in-out;

    &:hover {
      filter: grayscale(0%);
    }
  }
}

.partners-slider {
  display: flex;
  align-items: center;
  height: 200px;
  border-bottom: 1px solid rgba($color: #7f2aa7, $alpha: 0.3);
  background-color: #f9f9f9;
  .react-multi-carousel-list {
    position: initial;
  }
  ul {
    li {
      margin: auto;
    }
  }
  .partners-logo {
    .eko-metal {
      height: 80px;
    }
    .cloudcart {
      height: 70px;
    }
    img {
      max-width: 250px;
      max-width: 150px;
      height: auto;
    }
  }
  .partners-text {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #7f2aa7;
  }
}

@media screen and (max-width: 600px) {
  .react-multi-carousel-list {
    .partners-logo {
      .eko-metal {
        height: 60px;
      }
      .cloudcart {
        height: 50px;
      }
      img {
        max-width: 200px;
        max-width: 100px;
      }
    }
  }
}

@import "../common/SCSS/variables.scss";

.staff-wrapper {
  margin-top: 125px;
  .person-wrapper {
    .person-img-wrapper {
      width: 70%;
      display: block;
      margin: 0 auto;
      .person-img {
        width: 100%;
        object-fit: contain;
      }
    }
    .person-name {
      color: $primary-dark-purple;
      padding-left: 15px;
    }
    .person-description {
      padding: 15px;
    }
    .person-role {
      color: $primary-purple;
      font-size: 1.5rem;
      padding-left: 15px;
    }
  }
}

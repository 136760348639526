@import "../SCSS/variables.scss";

.service-button-wrapper {
  a {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    padding: 10px 25px;
    font-weight: bold;
    background-color: #7f2aa7;
    color: $primary-white;
    transition: 0.3s ease-in;
    width: 200px;
    text-align: center;
    margin-top: 50px;
    cursor: pointer;
  }

  .on-hover-purple {
    &:hover {
      background-color: #36094b;
    }
  }

  .on-hover-white {
    &:hover {
      background-color: #36094b;
      color: #7f2aa7;
    }
  }
}

.button-align-center {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .service-button-wrapper {
    a {
      font-size: 1rem;
      margin-top: 20px;
      width: 280px;
    }
  }
}

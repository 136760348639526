@import "../SCSS/variables.scss";

.hero-img-col {
  position: relative;
  max-width: 630px;
  width: 100%;
  max-height: 814px;
  height: 100%;
  display: flex;
  justify-content: center;

  .element-1,
  .element-1-animation {
    width: 310px;
    height: 541px;
    border-bottom: 2px solid rgb(210, 182, 218);
    border-top: 2px solid rgb(210, 182, 218);
    position: absolute;
    margin: 0 auto;
    transform: rotate(30deg) translateY(-100%);
    top: 79%;
    left: -1%;
    z-index: 1;
    transition: 1s ease;
    pointer-events: none;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: inherit;
      height: inherit;
      border-bottom: inherit;
      border-top: inherit;
    }

    &::before {
      transform: rotate(60deg);
    }
    &::after {
      transform: rotate(-60deg);
    }
  }

  .element-1-animation {
    transform: translateY(-90%) translateX(10%) rotate(30deg);
  }

  .img-wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;
    width: 570px;
    height: 630px;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    z-index: 5;
    background-color: $secondary-light-purple;
    img {
      object-fit: fill;
      z-index: 5;
    }
  }

  .element-2,
  .element-2-animation {
    width: 30px;
    height: 30px;
    position: absolute;
    background-color: $primary-dark-purple;
    border-radius: 5px;
    transform: rotate(45deg);
    left: -5%;
    top: 78%;
    transition: 1s ease;
    pointer-events: none;
  }

  .element-2-animation {
    transform: rotate(45deg) translateX(-200px) translateY(-600px);
    z-index: 6;
  }

  .element-3,
  .element-3-animation {
    width: 250px;
    height: 280px;
    position: absolute;
    border-radius: 5px;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    background-color: rgba(221, 196, 224, 0.4);
    top: 70%;
    left: 65%;
    z-index: 10;
    transition: 1s ease;
    pointer-events: none;
  }

  .element-3-animation {
    top: -5%;
    transform: translateX(100px);
  }

  .element-4,
  .element-4-animation {
    width: 70px;
    height: 70px;
    transform: rotate(45deg);
    border-radius: 5px;
    background-color: $primary-light-purple;
    position: absolute;
    top: 70%;
    right: -15%;
    z-index: 6;
    transition: ease 1s;
    pointer-events: none;
  }

  .element-4-animation {
    transform: rotate(140deg);
    top: 85%;
    right: 85%;
    z-index: 1;
  }

  .element-5,
  .element-5-animation {
    width: 150px;
    height: 180px;
    position: absolute;
    border-radius: 5px;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
    background-color: rgba(221, 196, 224, 0.4);
    top: -5%;
    left: 55%;
    z-index: 10;
    transition: 1s ease;
    pointer-events: none;
  }
  .element-5-animation {
    top: 80%;
    left: 60%;
  }

  .element-6,
  .element-6-animation {
    width: 60px;
    height: 60px;
    transform: rotate(45deg);
    border-radius: 5px;
    background-color: $primary-dark-purple;
    position: absolute;
    top: 5%;
    right: 6%;
    z-index: 5;
    transition: 1s ease;
    pointer-events: none;
  }
  .element-6-animation {
    right: 94%;

    transform: rotate(140deg);
  }
}

@media screen and (max-width: 1600px) {
  .hero-img-col {
    max-width: 530px;
    max-height: 714px;

    .element-1,
    .element-1-animation {
      width: 250px;
      height: 434px;
    }

    .img-wrapper {
      width: 450px;
      height: 510px;
    }

    .element-2,
    .element-2-animation {
      width: 25px;
      height: 25px;
    }
    .element-2-animation {
      transform: rotate(45deg) translateX(-100px) translateY(-550px);
      z-index: 6;
    }

    .element-3,
    .element-3-animation {
      width: 170px;
      height: 200px;
    }
    .element-4,
    .element-4-animation {
      width: 50px;
      height: 50px;
      right: -10%;
    }
    .element-5,
    .element-5-animation {
      width: 110px;
      height: 130px;
    }
    .element-6,
    .element-6-animation {
      width: 50px;
      height: 50px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .hero-img-col {
    max-width: 430px;
    max-height: 614px;

    .element-1,
    .element-1-animation {
      width: 199px;
      height: 345px;
    }

    .img-wrapper {
      width: 350px;
      height: 410px;
    }

    .element-2,
    .element-2-animation {
      width: 20px;
      height: 20px;
    }
    .element-2-animation {
      transform: rotate(45deg) translateX(-50px) translateY(-440px);
      z-index: 6;
    }

    .element-3,
    .element-3-animation {
      width: 110px;
      height: 130px;
    }
    .element-3-animation {
      top: -5%;
      transform: translateX(-15px);
    }
    .element-4,
    .element-4-animation {
      width: 30px;
      height: 30px;
      right: -5%;
    }
    .element-4-animation {
      transform: rotate(140deg);
      top: 85%;
      right: 95%;
      z-index: 1;
    }
    .element-5,
    .element-5-animation {
      width: 90px;
      height: 100px;
    }
    .element-6,
    .element-6-animation {
      width: 40px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 790px) {
  .hero-img-col {
    max-width: 440px;
    max-height: 514px;
    margin: 0 auto;

    .element-1,
    .element-1-animation {
      width: 193px;
      height: 334px;
      left: 5%;
    }

    .img-wrapper {
      width: 350px;
      height: 410px;
    }

    .element-2,
    .element-2-animation {
      width: 20px;
      height: 20px;
    }
    .element-2-animation {
      transform: rotate(45deg) translateX(-90px) translateY(-420px);
      z-index: 6;
    }

    .element-3,
    .element-3-animation {
      width: 140px;
      height: 170px;
    }

    .element-3-animation {
      top: -1%;
      transform: translateX(75px);
    }

    .element-4,
    .element-4-animation {
      width: 40px;
      height: 40px;
    }
    .element-5,
    .element-5-animation {
      width: 90px;
      height: 100px;
    }
    .element-6,
    .element-6-animation {
      width: 35px;
      height: 35px;
    }
  }
}

@media screen and (max-width: 632px) {
  .hero-img-col {
    max-width: 280px;
    max-height: 384px;
    margin: 0 auto;
    display: none;

    .element-1,
    .element-1-animation {
      width: 57.5px;
      height: 100px;
      left: -5%;
    }

    .img-wrapper {
      width: 100px;
      height: 110px;
    }

    .element-2,
    .element-2-animation {
      width: 10px;
      height: 10px;
      border-radius: 2px;
    }
    .element-2-animation {
      transform: rotate(45deg) translateX(-20px) translateY(-260px);
      z-index: 6;
    }

    .element-3,
    .element-3-animation {
      width: 30px;
      height: 35px;
    }

    .element-3-animation {
      top: -1%;
      transform: translateX(25px);
    }

    .element-4,
    .element-4-animation {
      width: 20px;
      height: 20px;
      top: 70%;
      right: 80%;
    }
    .element-5,
    .element-5-animation {
      width: 40px;
      height: 50px;
    }
    .element-6,
    .element-6-animation {
      width: 15px;
      height: 15px;
      right: 70%;
    }
  }
}

@import "../../common/SCSS/variables.scss";
.light-purple-section {
  background-color: #e1dbf3;
  padding: 80px 0px;
  .main-services-page-light-purple-paragraphs {
    font-weight: 500;
    margin-bottom: 5px;
  }
  .cards-row {
    .primary-card-wrapper {
      background-color: $primary-white;
      border-radius: 5px;
      height: 420px;
      border-top: 15px solid $primary-purple;
      // box-shadow: 0px 5px 10px $primary-dark-purple;
    }
  }
}

@media screen and (max-width: 1210px) {
  .light-purple-section {
    .cards-row {
      .primary-card-wrapper {
        height: 550px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .light-purple-section {
    .cards-row {
      .primary-card-wrapper {
        height: initial;
      }
    }
  }
}

@import "../SCSS/variables.scss";

h1 {
  font-size: 4.5rem;
  font-weight: bolder;
}

h2 {
  font-size: 2.5rem;
}

.dark {
  color: $primary-dark-purple;
}

.light {
  color: $primary-purple;
}

.section-header-white,
.section-header-purple {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 60px;
}

.section-header-white {
  color: $primary-white;
}
.section-header-purple {
  color: $primary-dark-purple;
}

.center {
  text-align: center;
}

@media screen and (max-width: 1600px) {
  h1 {
    font-size: 3.5rem;
  }
  .section-header-white,
  .section-header-purple {
    font-size: 2.3rem;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
  }
  .section-header-white,
  .section-header-purple {
    font-size: 1.8rem;
  }
}

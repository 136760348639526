@import "../SCSS/variables.scss";

.section-text-color-white,
.section-text-color-black,
.section-text-color-purple {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.section-text-color-white {
  color: $primary-white;
}

.section-text-color-black {
  color: $primary-black;
}

.section-text-color-purple {
  color: $secondary-dark-purple;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

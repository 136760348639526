@import "../../common/SCSS/variables.scss";

.drop-menu {
  position: absolute;
  list-style: none;
  text-align: start;
  background-color: #fcf7f7;
  padding: 15px;
  top: 60px;
  box-shadow: 0px 0px 5px #b4b2b2;
  border-spacing: 10px;
  border-radius: 4px;
  z-index: 5;

  li {
    margin: 5px 0px;
    border-bottom: 1px solid rgba($color: #7f2aa7, $alpha: 0.3);
    cursor: pointer;
    a {
      color: #7f2aa7;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

.dropdown-hide {
  display: none;
}

@media screen and (max-width: 1000px) {
  .drop-menu {
    position: relative;
    background-color: initial;
    padding: 0px;
    top: 0px;
    box-shadow: initial;
    border-spacing: none;
    border-radius: none;
    z-index: 56;
    ul {
      margin-bottom: 0rem;
    }

    li {
      margin: 13px 0px;
      border-bottom: none;
      cursor: pointer;

      a {
        color: $primary-purple;
        text-decoration: none;
        font-weight: bold;

        svg {
          display: none;
        }
      }
    }
  }
}

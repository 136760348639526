@import "../../common/SCSS/variables.scss";
.purple-section {
  background-color: #2d1768;
  padding: 80px 0px;

  .purple-section-row {
    align-items: center;
  }
  .text-column {
    h2 {
      color: #fff;
      text-transform: uppercase;
      font-size: 2.5rem;
      margin-bottom: 60px;
      font-weight: bolder;
    }

    a {
      display: block;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 22px;
      padding: 10px 25px;
      font-weight: bold;
      background-color: #7f2aa7;
      color: #fff;
      transition: 0.3s ease-in;
      width: 200px;
      text-align: center;
      margin-top: 50px;
      &:hover {
        background-color: #fff;
        color: #7f2aa7;
      }
    }
  }
  img {
    max-width: 700px;
    position: absolute;
    object-fit: cover;
    left: 44%;
    transform: translateX(-50%);
    z-index: 7;
  }

  .purple-section-img-wrapper {
    width: 480px;
    height: 480px;
    background-color: $primary-white;
    margin-left: 100px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
  }
}

@media screen and (max-width: 1210px) {
  .purple-section {
    img {
      max-width: 400px;
    }

    .purple-section-img-wrapper {
      width: 380px;
      height: 380px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .purple-section {
    .text-column {
      h2 {
        font-size: 1.8rem;
      }
    }
    .purple-section-img-wrapper {
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .purple-section {
    img {
      max-width: 200px;
    }

    .purple-section-img-wrapper {
      width: 200px;
      height: 200px;
    }
  }
}

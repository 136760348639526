.comment-section {
  margin-bottom: 80px;

  ul {
    padding: 30px 0px;
  }

  .client-comments-header {
    color: #7f2aa7;
    text-align: center;
    margin: 70px 0px 80px;
    font-weight: bolder;
    font-size: 3rem;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 900px) {
  .comment-section {
    .client-comments-header {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .comment-section {
    .client-comments-header {
      font-size: 1.8rem;
    }
  }
}

.primary-card-wrapper {
  border: 2px solid #a891e7;
  padding: 10px 40px;
  position: relative;
  box-shadow: 0px 10px 16px #c0aafc;
  min-height: 300px;
  margin-top: 50px;
  .card-icon-wrapper {
    background-color: #36094b;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 26px;
    margin: 0 auto;
    transform: rotate(45deg);
    position: absolute;
    top: -25px;
    left: 44%;

    svg {
      transform: rotate(-45deg);
    }
  }

  h2 {
    text-align: center;
    margin: 40px 0px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 1405px) {
  .primary-card-wrapper {
    h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 1220px) {
  .primary-card-wrapper {
    h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 990px) {
  .primary-card-wrapper {
    h2 {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 440px) {
  .primary-card-wrapper {
    h2 {
      font-size: 1.5rem;
    }
  }
}

@import "../common/SCSS/variables.scss";

.article-wrapper {
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0px 10px;

  .article-img-wrapper {
    width: 600px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .article-title {
    margin: 15px 0 30px;
    text-decoration: underline;
    font-size: 3rem;
    font-family: sans-serif;
    font-weight: bold;
  }
  .extra-buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    .edit-btn-wrapper {
      margin-top: 30px;
      .edit-btn {
        background-color: $primary-purple;
        padding: 10px 20px;
        display: inline-block;
        color: $primary-white;
        text-decoration: none;
        font-weight: bold;
        border-radius: 4px;
        transition: 0.5s ease;
        border: none;

        &:hover {
          background-color: $secondary-purple;
        }
      }
    }

    .delete-btn {
      background-color: $primary-purple;
      padding: 10px 27px;
      display: inline-block;
      color: $primary-white;
      text-decoration: none;
      font-weight: bold;
      border-radius: 4px;
      transition: 0.5s ease;
      margin-top: 30px;
      border: none;

      &:hover {
        background-color: $secondary-purple;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .article-wrapper {
    .article-img-wrapper {
      width: initial;
    }
  }
}

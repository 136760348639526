@import "../../common/SCSS/variables.scss";
.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgba($color: #7f2aa7, $alpha: 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background-color: #fff;

  .services {
    padding: 20px 0px;
  }

  .mobile-menu-wrapper {
    display: none;
  }
  .logo {
    img {
      width: 250px;
      height: auto;
    }
  }
  .desktop-nav,
  .show-mobile-nav {
    display: flex;
    gap: 20px;
    align-items: center;

    a {
      display: table-cell;
      text-decoration: none;
      font-weight: bold;
      color: #7f2aa7;
      // padding: 15px 0px;

      .arrow-down {
        font-size: 12px;
      }
    }
    .contacts {
      background-color: $primary-purple;
      color: #fff;
      padding: 10px 15px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #fff;

      &:hover {
        box-shadow: 0px 0px 5px whitesmoke;
      }
    }
    .services {
      padding: 15px 0px;
    }
  }
  .active {
    text-decoration: underline;
    text-decoration-color: #7f2aa7;
  }
  .mobile-menu-btn {
    color: $primary-dark-purple;
    font-size: 2rem;
    display: none;
    position: absolute;
    top: 25px;
    right: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .nav-bar {
    .mobile-menu-wrapper {
      display: block;
    }
    .mobile-menu-btn {
      display: block;
    }
    .desktop-nav,
    .show-mobile-nav {
      display: flex;
      position: absolute;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      top: 74px;
      left: 0;
      right: 0;
      padding: 20px 0px;
      background-color: rgba($color: #000000, $alpha: 0.95);
      transition: 1s ease-in-out;
      opacity: 0;
      transform: translateY(-120%);
      z-index: 5;

      .services {
        padding: 0;
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $primary-white;
      }
    }
    .show-mobile-nav {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

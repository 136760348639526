.cookie {
  position: fixed;
  background-color: #7f2aa7e5;
  width: 29rem;
  height: 11rem;
  display: flex;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  bottom: 1rem;
  left: 1rem;
  flex-direction: column;
  gap: 20px;
  z-index: 111;
  .cookie-text {
    color: white;
    line-height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cookie-btn {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    box-sizing: border-box;

    &-item {
      background-color: white;
      padding: 4px 10px;
      margin: 0;
      text-decoration: none;
      font-size: 14px;
      border-radius: 1rem;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }
  }
}

@media only screen and (max-width: 700px) {
  .cookie {
    width: 90%;
    height: auto;
    left: 0;
  }
}

.calendly-wrapper {
  background-color: #2d1768;
  padding: 50px 0px;

  .calendly-header {
    color: #fff;
    text-align: center;
    padding: 20px 0px 50px;
    font-weight: bolder;
    font-size: 3rem;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 900px) {
  .calendly-wrapper {
    .calendly-header {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 600px) {
  .calendly-wrapper {
    .calendly-header {
      font-size: 1.8rem;
    }
  }
}

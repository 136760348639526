@import "../../common/SCSS/variables.scss";

.main-services-custom-section {
  .services-element-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .services-element-text,
  .services-element-img-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 400px;
    padding: 250px 0px;
  }

  .services-element-text {
    background-color: $primary-white;
    .services-element-header {
      color: $primary-black;
      font-size: 2rem;
      margin-bottom: 30px;
      text-align: center;
    }
    .service-element-paragraph {
      padding: 0px 50px;
      font-size: 1rem;
      color: $primary-black;
    }
  }

  .services-element-img-wrapper {
    background-color: $primary-white;
    .service-element-img {
      padding: 0px;
      margin: 0px;
      max-width: 540px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 1100px) {
  .main-services-custom-section {
    .services-element-text,
    .services-element-img-wrapper {
      height: 700px;
    }
  }
}

@media screen and (max-width: 800px) {
  .main-services-custom-section {
    .services-element-text,
    .services-element-img-wrapper {
      height: 1000px;
    }
  }
}

@media screen and (max-width: 600px) {
  .main-services-custom-section {
    .services-element-wrapper {
      flex-direction: column;
      width: 100%;
    }
    .reverse-on-mobile {
      flex-direction: column-reverse;
    }
    .services-element-img-wrapper {
      width: 100%;
      height: 200px;
      padding: 150px 0px;
    }
    .services-element-text {
      height: 810px;
      width: 100%;
      .services-element-header {
        font-size: 1.8rem;
        text-align: center;
      }
    }
  }
}

@import "../common/SCSS/variables.scss";
.article-card-wrapper {
  border: 2px solid $primary-purple;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
  height: 800px;

  .article-card-img {
    img {
      width: 100%;
      cursor: pointer;
      height: 400px;
      object-fit: cover;
    }
  }

  .article-card-text {
    a {
      text-decoration: none;
    }
    .article-card-title {
      color: $primary-purple;
    }
    .article-card-info {
      span {
        font-weight: bold;
        margin-right: 5px;
        color: $primary-purple;
      }
    }
    time {
      .calendar-icon {
        margin-right: 5px;
        color: $primary-purple;
      }
      font-weight: 400;
      font-style: italic;
      color: $primary-purple;
    }
  }
}

@media screen and (max-width: 1400px) {
  .article-card-wrapper {
    height: auto;
  }
}

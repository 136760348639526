.content-marketing-hero-section {
  margin-top: 100px;
  padding: 70px 0px;
}
@media screen and (max-width: 1000px) {
  .content-marketing-hero-section {
    margin-top: 50px;
    padding: 40px 0px;
  }
}

@media screen and (max-width: 800px) {
  .content-marketing-hero-section {
    margin-top: 30px;
  }
}

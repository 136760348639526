@import "../common/SCSS/variables.scss";
.portfolio-projects-section {
  background-color: #f1edf3;
  padding: 80px 0px;
  h2 {
    text-transform: uppercase;
    font-size: 2.5rem;
    margin-bottom: 60px;
    font-weight: bolder;
  }
}

@media screen and (max-width: 1000px) {
  .portfolio-projects-section {
    h2 {
      font-size: 1.8rem;
    }
  }
}

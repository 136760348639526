.why-us-wrapper {
  padding: 80px 0px;
  .col-lg-4 {
    width: 70%;
  }
}

@media screen and (max-width: 1400px) {
  .about-us-primary-cards {
    height: auto;
  }
}

@media screen and (max-width: 1300px) {
  .why-us-wrapper {
    .primary-card-extra-class {
      height: 320px;
      min-height: 320px;
    }
  }
}

@media screen and (max-width: 770px) {
  .why-us-wrapper {
    .primary-card-extra-class {
      height: 320px;
      min-height: 320px;
    }

    .about-us-primary-cards {
      height: auto;
    }
  }
}

@media screen and (max-width: 520px) {
  .why-us-wrapper {
    .primary-card-extra-class {
      height: 320px;
      min-height: 320px;
    }

    .about-us-primary-cards {
      height: auto;
    }
  }
}

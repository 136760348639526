@import "../common/SCSS/variables.scss";
.portfolio-project-wrapper {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  background-color: $secondary-purple;
  border-radius: 5px;
  position: relative;
  margin: 0px auto 40px;
  .portfolio-project-img-wrapper {
    cursor: pointer;
    img {
      width: 100%;
      object-fit: cover;
      height: 290px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: relative;
      transition: 1s ease-in;
    }
    .portfolio-project-image-sticker {
      display: none;
    }
    .portfolio-project-image-sticker,
    .portfolio-project-image-sticker-show {
      justify-content: center;
      align-items: center;
      color: $primary-white;
      font-weight: bolder;
      width: 100px;
      height: 100px;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      background-color: rgba($color: #2d1768, $alpha: 0.8);
      box-shadow: 0px 0px 20px #9e1faa;
      position: absolute;
      transition: 2s ease-in;
      opacity: 0;
      p {
        margin: 0;
        padding: 0;
      }
    }

    .portfolio-project-image-sticker-show {
      display: flex;
      opacity: 1;
    }
  }

  .portfolio-project-header {
    text-align: center;
    color: $primary-white;
    margin-top: 10px;
  }
}

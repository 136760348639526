@import "../../../common/SCSS/variables.scss";

.portfolio-client-wrapper {
  .portfolio-client-header {
    margin: 150px 0px 50px;
    text-align: center;
  }

  .portfolio-info-main-img {
    width: 100%;
    margin-bottom: 50px;
    border-radius: 5px;
    box-shadow: 0px 5px 15px #c0c0c0;
  }

  .portfolio-info-client-logo {
    width: 40%;
  }

  .portfolio-info-client-data-box-left {
    margin: 20px 0px;
    a {
      color: $primary-black;
    }
  }

  .portfolio-info-client-data-header {
    margin: 50px 0px 20px;
  }

  .portfolio-info-client-data-services {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    li {
      list-style: none;
      width: 400px;
    }
  }
  .portfolio-info-client-mockup-img-wrapper {
    margin: 50px 0px 0px;
    .portfolio-info-client-mockup-img {
      width: 100%;
    }
  }
  a {
    color: $primary-purple;
  }
}

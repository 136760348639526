@import "../common/SCSS/variables.scss";

.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 350px;
  background-color: rgb(245, 245, 245);
  margin: 15% auto 0;
  border: 2px solid $primary-purple;
  border-radius: 15px;
  box-shadow: 0px 0px 15px #9c9c9c;

  .login-form {
    width: 100%;
    box-sizing: border-box;
    margin-top: 0;
    .login-header {
      margin-bottom: 20px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .login-inputs {
    width: 60%;
    border-radius: 5px;
    padding: 10px;
    border: none;
  }
  .login-btn {
    width: 45%;
    border-radius: 30px;
    height: 50px;
    font-weight: bold;
    color: $primary-white;
    background-color: $primary-purple;
    transition: 1s ease;

    &:hover {
      background-color: $primary-pink;
      color: $primary-black;
    }
  }
}

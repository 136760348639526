//-----Constant Colors-----//
$primary-purple: #7f2aa7;
$secondary-purple: #2d1768;
$third-purple: #7500eb;
$primary-dark-purple: #36094b;
$secondary-dark-purple: #33245b;
$primary-light-purple: #a891e7;
$primary-white: #fff;
$primary-black: #000000;
$primary-pink: #f1d6e2;
$secondary-light-purple: #e1dbf3;

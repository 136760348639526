@import "../common/SCSS/variables.scss";

.post-editor-wrapper {
  width: 100%;
  height: 100vh;
  background-color: #fdf9f9;
  .post-editor-form {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 20px;
    margin: 0 auto;
    .post-editor-inputs {
      border-radius: 10px;
      width: 100%;
      max-width: initial;
      align-items: center;
    }
  }

  .post-editor-main-header {
    margin-top: 80px;
    text-align: center;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    font-size: 4rem;
    color: $primary-purple;
  }
  .post-editor-btn {
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1.5rem;
    background-color: $primary-purple;
    color: $primary-white;
    font-weight: bold;
    padding: 10px 0px;
    transition: 0.5s ease-in;

    &:hover {
      background-color: $primary-dark-purple;
      box-shadow: 0px 0px 20px #c509ac;
    }
  }
}

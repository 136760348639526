.footer {
  background-color: black;
  color: #fff;
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 80px;
  a {
    columns: #fff;
  }
  bottom: 0;
  right: 0;
  left: 0;

  .copyright {
    p {
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .beta-portal {
    background: rgb(2, 21, 89);
    background: linear-gradient(
      115deg,
      rgba(2, 21, 89, 1) 0%,
      rgba(127, 42, 167, 1) 66%
    );
    display: table;
    width: 120px;
    height: 50px;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #fff;
    &:hover {
      box-shadow: 0px 0px 5px whitesmoke;
    }

    a {
      display: table-cell;
      vertical-align: middle;
      color: #fff;
      text-decoration: none;
    }
  }
}
